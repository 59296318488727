import {
  Button,
  Card,
  CardBody,
} from "@material-tailwind/react";
import PropTypes from "prop-types";
import db from "../../firebase";
import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import React from "react";
import { UserAuth } from "../../context/AuthContext";
import DashboardEditDialog from "./dashboard-edit-dialog";

export function DashboardCardPlaceholder() {

  const [open, setOpen] = React.useState(false);
  const { user } = UserAuth();

  const doAdd = async(name, manufacturer, model) => { 

    console.log(`add new dashboard summary...`);
    console.log(`update for user data`);
    const docRef = doc(db, "user_data", user.uid);
    
    const manufacturers = [manufacturer];
    const models = model;

    const newDashPanelObj = {
      name,
      manufacturers,
      models,
      created: serverTimestamp()
    }

    console.log(`adding dash panel with data`, newDashPanelObj);
    
    const docRefCol = collection(db, "user_data", user.uid, "dPanels");
    await addDoc(docRefCol, newDashPanelObj);

    console.log("Document written with ID: ", docRef.id);
  }

  const handleOpen = (data) => {
    setOpen(true);
  };
  
  const handleQuit = () =>{
    console.log(`quitting...`);
    setOpen(false);
  }

  const handleSave = async (name, selectedManufacturer, selectedModel) => {
    console.log(`closing...${name}`, selectedManufacturer, selectedModel);
    await doAdd(name, selectedManufacturer, selectedModel )
    setOpen(false);
  }


  return (
      <>
    <DashboardEditDialog 
      mode={"add"}
      open={open} 
      handleSave={handleSave}
      handleQuit={handleQuit}
      handleOpen={handleOpen}
    />

    <Card>
      {/* <CardHeader
        variant="gradient"
        color={color}
        className="absolute -mt-4 grid h-16 w-16 place-items-center"
      >
        {icon}
      </CardHeader> */}
      <CardBody className="text-center h-200">
        <div className="h-200" >

        </div>
        <Button onClick={(e) => handleOpen()}> Add </Button>
      </CardBody>
      {/* {footer && (
        <CardFooter className="border-t border-blue-gray-50 p-4">
          {footer}
        </CardFooter>
      )} */}
    </Card></>

  );
}

DashboardCardPlaceholder.defaultProps = {
  color: "blue",
  footer: null,
};

DashboardCardPlaceholder.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  icon: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
};

DashboardCardPlaceholder.displayName = "/src/widgets/cards/dashboard-card-placehodler.jsx";

export default DashboardCardPlaceholder;
