import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import {
  and,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  endBefore,
  limitToLast,
  where,
  getCountFromServer,
} from "firebase/firestore";
import React, { useState, useRef, useContext, useCallback, useEffect } from "react";

import db from "../../firebase";

import {
  connectFunctionsEmulator,
  getFunctions,
} from "firebase/functions";
import { Link} from "react-router-dom";

import {
  formatPrice,
  formatSource,
  friendlyTimeFormat,
  friendlyModelName,
  friendyManufacturerName,
  NO_PRICE_STR,
  formatTotalTime
} from "../../utils";
import {
  useCollection,
} from "react-firebase-hooks/firestore";

import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../context/DataContext";
import { DefaultPagination } from "../../components/Pagination";
import { Button } from "react-bootstrap";

const functions = getFunctions();

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export function ListingsView({models, manufacturer, title}) {

  const dataContext = useContext(DataContext);
  const [page, setPage] = useState(1);
  const [listingsCount, setListingCount] = useState();
  const [sortField, setSortField] = useState("first_seen");
  const [sortDirection, setSortDirection] = useState("asc");

  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const COLLECTION = "listing_aggregations_v1";

  let modelIds = []
  for (let i =0;i<models.length;i++){
    modelIds = modelIds.concat(models[i].value.ids);
  }

  const wheres = [];
  wheres.push(where("model", "in", modelIds))
  wheres.push(where("manufacturer", "in", manufacturer))
  
  const docCursors = useRef()
  const listingsPerPage = 10;
  const paginationConstraints =[];

  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      paginationConstraints.push(startAfter(docCursors.current.docToStartAfter))
      paginationConstraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      paginationConstraints.push(endBefore(docCursors.current.docToEndBefore))
      paginationConstraints.push(limitToLast(listingsPerPage));
    }
  } else {
    paginationConstraints.push(limit(listingsPerPage));
  }

  const pageConstraints = []

  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      pageConstraints.push(startAfter(docCursors.current.docToStartAfter))
      pageConstraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      pageConstraints.push(endBefore(docCursors.current.docToEndBefore))
      pageConstraints.push(limitToLast(listingsPerPage));
    }
  } else {
    pageConstraints.push(limit(listingsPerPage));
  }

  const queryNewListingsData = query(
    collection(db, COLLECTION),
    and(...wheres),
    orderBy(sortField, sortDirection),
    ...pageConstraints
  );

  const queryNewListingsDataNoLimit = query(
    collection(db, COLLECTION),
    and(...wheres),
  );

  const [listingsValue] = useCollection(queryNewListingsData, options);

  const handlePageChange = useCallback((nextPage)=>{
    if (listingsValue){
      setPage((page)=>{
        // console.log(`current page is ${page}, next page is ${nextPage}`)

        if (nextPage < page){
          // console.log('setting doc to end before ', listingsValue.docs[0])
          docCursors.current = { direction: "back", docToEndBefore: listingsValue.docs[0]};
        } else {
          // console.log('setting doc to start after ',  listingsValue.docs[listingsValue.docs.length-1])
          docCursors.current = { direction: "forward", docToStartAfter: listingsValue.docs[listingsValue.docs.length-1]};
        }
        // console.log('doc Cursors', docCursors)
        return nextPage;
      })
    }
  }, [listingsValue])

  useEffect(()=>{
    getCountFromServer(queryNewListingsDataNoLimit)
    .then((snapshot)=>{
      console.log(`got count for query without limit:${snapshot.data().count}`)
      setListingCount(snapshot.data().count);
    })
  }, [queryNewListingsDataNoLimit])


  function flipSortDirection(clickedField, currentSortField){
    if (clickedField === currentSortField){
      if (sortDirection === "desc"){
        setSortDirection("asc")
      } else {
        setSortDirection("desc")
      }
    }
  }


  const handleHeaderClick = (el) => {

    setSortField(el.value);
    flipSortDirection(el.value, sortField);
    console.log(`sort set to ${sortField} ${sortDirection}`);
    setPage(1);
  }

  return (
    <div className="mt-12">

      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            <div className="flow-root">
              <Typography variant="h6" color="white" className="float-left">
                {title}
              </Typography>
            </div>
          </CardHeader>
          <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            <table className="w-full min-w-[640px] table-auto">
              <thead>
                <tr>
                  {[
                    {label: "Title", value:"title"},
                    {label: "Price", value:"price_usd"},
                    {label: "Price Change", value: "price_change_usd"},
                    {label: "Price Change %", value: "price_change_pct_abs_usd"},
                    {label: "year", value: "year"},
                    {label: "model", value: "model"},
                    {label: "source", value: "source"},
                    {label: "total time", value: "timeState.totalTime"},
                    {label: "first seen", value:"first_seen"},
                    {label: "Days active", value:"days_active"}
                  ].map((el) => (
                    <th 
                      key={el.value}
                      className="border-b border-blue-gray-50 py-3 px-5 text-left"
                    >
                      <Button onClick={(e) => handleHeaderClick(el)}>
                      <Typography
                        variant="small"
                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                      >
                        {el.label} {el.value === sortField ? sortDirection === "asc" ? "↑" : "↓": ""}
                        </Typography>
                      </Button>
                      
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listingsValue &&
                  listingsValue.docs.map((doc, index) => {
                    const className = `py-3 px-5 ${
                      index === listingsValue.docs.length - 1
                        ? ""
                        : "border-b border-blue-gray-50"
                    }`;

                    return (
                      <tr key={`row${index}`}>
                        <td className={className}>
                            {/* <Avatar
                              src={doc.data().imageURL}
                              alt={index}
                              size="sm"
                            /> */}
                            <Link className="flex items-center gap-4"
                              to={`/dashboard/listing-details/${
                                doc.data().uniqueListingId
                              }`}
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                              {doc.data().year} {friendyManufacturerName(dataContext, doc.data().manufacturer)} {friendlyModelName(dataContext, doc.data().model)} 

                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className=""
                              >
                                {doc.data().reg}
                              </Typography>
                            </Link>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatPrice(doc.data().price, doc.data().currency)}
                          </Typography>
                        </td>
                        <td className={className}>
                        <Typography
                          variant="small"
                          color={doc.data().price_change_usd >= 1 ? "green" : "red"}
                          className="text-xs font-medium "
                        >
                          {formatPrice(
                            doc.data().price_change_usd,
                            "USD"
                          )}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          color={doc.data().price_change_pct_abs_usd >= 1 ? "green" : "red"}
                          className="text-xs font-medium "
                        >
                           {isNaN(doc.data().price_change_pct_abs_usd) ? NO_PRICE_STR : Math.floor(doc.data().price_change_pct * 100) + "%"}
                        </Typography>
                      </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {doc.data().year}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendlyModelName(dataContext, doc.data().model)}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatSource(doc.data().source)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatTotalTime(doc.data().timeState.totalTime)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendlyTimeFormat(doc.data().first_seen)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {Math.round(doc.data().days_active)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </CardBody>
        </Card>
        {listingsValue &&<DefaultPagination page={page} handlePageChange={handlePageChange} listingsCount={listingsCount} listingsPerPage={listingsPerPage}></DefaultPagination>}
      </div>

    </div>
  );
}

export default ListingsView;
