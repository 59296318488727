import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
} from "@material-tailwind/react";
import {
  CheckIcon,
  ArrowUpIcon,
  ChartBarIcon,
  TicketIcon,
  CreditCardIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { StatisticsCard } from "widgets/cards/statistics-card";
import { projectsTableData, ordersOverviewData } from "data";
import { collection, doc, orderBy, query } from "firebase/firestore";
import db from "../../firebase";
import { ArrowDownIcon, ArrowLeftCircleIcon, CalendarDaysIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { formatFirebaseTimestamp, getUniquePriceHistoryChanges } from "utils";
import { formatPrice, friendlyTimeFormat, locationFromListingScrapeData } from "../../utils";
import { ListingDetailImage } from "../../components/ListingDetailImage";
import { ListingDetailsChart } from "../../components/ListingDetailsChart";
import { Button } from "react-bootstrap";
import { UserAuth } from "../../context/AuthContext";

const ListingDetails = () => {
  const { slug } = useParams();
  const { isAdmin } = UserAuth();

  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const col = "listing_aggregations_v1";
  const queryListing = query(doc(db, `${col}/${slug}`));
  const [listingData] = useDocument(queryListing, options);

  const queryListingScrapes = query(
    collection(db, `${col}/${slug}/listing_scrapes`),
    orderBy("scrapeTs", "asc"),
    // limit(1)
  );
  
  const [listingScrapesData] = useCollection(queryListingScrapes, options);

  function timeseriesFromData(data) {
    let priceUSDOfLastPriceChange = data[0].data().price_usd;
    const points = data.map((item, index) => {
      const dt = item.data().scrapeTs.toDate();
      if (index !== 0){
        if (item.data().currency === data[index-1].data().currency){
          // use the last price_usd unless the underlying price has changed
          const price = item.data().price;
          const prevPrice = data[index-1].data().price;
          if (price !== prevPrice){
            priceUSDOfLastPriceChange = item.data().price_usd;
            console.log(`price diff ${dt} ${price} ${prevPrice}`)
            return {dt, price: item.data().price_usd};
          } else {
            return {dt, price: priceUSDOfLastPriceChange};
          }
        } else {
          priceUSDOfLastPriceChange = item.data().price_usd;
          return {dt, price: item.data().price_usd};
        }
      } else {
        return {dt, price: item.data().price_usd};
      }

    });
    return points;
  }

  function allNaN(timeseries){
    return timeseries.filter(item => isNaN(item.price)).length === timeseries.length;
  }

  const navigate = useNavigate()

  const handleBack = ()=>{
    navigate(-1);
  }
  const functions = getFunctions();
  
  if (window.location.href.indexOf('localhost') > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

const functionName = "cleanPriceAndReprrocessListingAggregation";
const reprocess = httpsCallable(functions, functionName);

  const handleReprocess = async (doc) => {
    console.log(`reprocess clicked: `,doc.id, doc.data());
    const response = await reprocess({uniqueListingId: doc.id});
    console.log(response)
  };
  

  const lastListingScrape = () => {
    const latestListingScrapeData = listingScrapesData.docs[listingScrapesData.docs.length-1].data();
    return latestListingScrapeData;
  }

  const dealerInfo = () => {

    const ret = {
      dealerName: 'Loading...',
      dealerPhoneNumber: "Loading...",
      dealerLocation: "Loading..."
    }

    if (!listingScrapesData){
      return ret;
    }

    const latestListingScrapeData = lastListingScrape();

    console.log(`listing scrape data`, latestListingScrapeData)

      if (listingScrapesData){
        ret.dealerName = latestListingScrapeData.dealerName;
        if (latestListingScrapeData.dealerName){
          ret.dealerName = ret.dealerName.replaceAll(`&amp;`,``);
          ret.dealerName = ret.dealerName.replaceAll(` ,`,`,`);
        }

        ret.dealerPhoneNumber = latestListingScrapeData.dealerPhoneNumber;
        
        ret.individualName = latestListingScrapeData.individualName;
        if (latestListingScrapeData.individualName){
          ret.individualName = ret.individualName.replaceAll(`&amp;`,``);
          ret.individualName = ret.individualName.replaceAll(` ,`,`,`);
        }

        ret.dealerLocation = latestListingScrapeData.dealerLocation;
        if (latestListingScrapeData.dealerLocation){
          ret.dealerLocation = ret.dealerLocation.replaceAll(`&amp;`,``);
          ret.dealerLocation = ret.dealerLocation.replaceAll(` ,`,`,`);
        }
        

      } else {
        console.log('no listing scrapes');
      }
    return ret;
  }


  return (
    
    <div className="mt-12 p-0">
        <div className="mb-10 p-0">
          {/* <Button onClick={handleBack}> {"<-"}Back </Button> */}
          <IconButton
            variant="text"
            color="blue"
            onClick={handleBack}
            className="border-solid border-2"
          >
            <ArrowLeftCircleIcon className="h-7 w-7 text-blue-500" />
          </IconButton>
          {listingData && listingData.data() && isAdmin &&
            <Button key={`reprocess_${listingData.data().uniqueListingId}`} className="border p-1 bg-blue-300 text-white" onClick={()=>handleReprocess(listingData)}>Re-process</Button>
          }

        </div>
      <div className="mb-12 grid gap-x-6 grid-cols-3">

      <div className="col-span-1">

          {listingData && 
            <ListingDetailImage
            src={listingData.data().imageURL}
            price={
              formatPrice(listingData.data().price, listingData.data().currency)
            }
            title={listingData.data().title}
            reg={listingData.data().reg}
            site={listingData.data().source}
            listingURL={listingData.data().listingURL}
            lastSeen={listingData.data().last_seen}
            location={locationFromListingScrapeData(lastListingScrape())}
            keyword={listingData.data().keyword}
            country={listingData.data().country}
            year={listingData.data().year}
          />
       
          }
        </div>          
        <div className="col-span-2">
            {listingScrapesData && (!allNaN(timeseriesFromData(listingScrapesData.docs)) ? (
              <Card className="">
              <CardBody>
                <ListingDetailsChart
                  className="xl:col-span-3"
                  timeseries={timeseriesFromData(listingScrapesData.docs)}
                  priceChange={listingData.data().price_change_usd}
                  price={listingData.data().price}
                ></ListingDetailsChart>
              </CardBody>
          </Card>
            ) : <div />)}
            
        </div>
      </div>

      <div className="mb-12 grid gap-y-5 gap-x-3 md:grid-cols-5 xl:grid-cols-5">

      {listingData && listingData.data().timeState && (
        <StatisticsCard
          key={"timing"}
          value={
            listingData.data().timeState.totalTime
          }
          title={"Total Time"}
          icon={React.createElement(ClockIcon, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <>  
              <Typography className="font-normal text-sm text-blue-gray-600">
                Number of Engines: {listingData.data().timeState.numberOfEngines}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Engine 1: {listingData.data().timeState.engine1Time}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Engine 2: {listingData.data().timeState.engine2Time}
              </Typography>
            </>
         }
        />)}


      {listingData && (
        <StatisticsCard
          key={"dealer"}
          value={
            dealerInfo().dealerPhoneNumber
          }
          title={"Dealer"}
          icon={React.createElement(PhoneIcon, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <>  
              <Typography className="font-normal text-sm text-blue-gray-600">
              {dealerInfo().dealerName}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Contact: {dealerInfo().individualName}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Location: {dealerInfo().dealerLocation}
              </Typography>
            </>
         }
        />)}

      {listingData && (
        <StatisticsCard
          key={"price"}
          //   rest={"jello"}
          value={
            listingData ? formatPrice(listingData.data().price, listingData.data().currency) : "loading..."
          }
          title={"Current Price"}
          icon={React.createElement(CreditCardIcon, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <Typography className="font-normal text-sm text-blue-gray-600">
              {"active for "}
              <strong className={"text-blue-gray"}>
                {Math.ceil(listingData.data().days_active)}</strong>&nbsp;{ Math.ceil(listingData.data().days_active) === 1 ? "day" : "days"}
            </Typography>
          }
        />)}

      {listingData && (<StatisticsCard
          key={"price_change"}
          //   rest={"jello"}
          value={
            listingData ? formatPrice(listingData.data().price_change_usd, "USD") : "loading..."
          }
          title={"Price Change vs. original"}
          icon={React.createElement(ChartBarIcon, {
            className: "w-6 h-6 text-red",
          })}
          footer={
            <Typography className="font-normal text-sm text-blue-gray-600">
              <strong
                className={
                  listingData && listingData.data().price_change_pct >= 0
                    ? "text-green-500"
                    : "text-red-500"
                }
              >
                {Math.abs(listingData.price_change) > 0 ? Math.ceil(listingData.data().price_change_pct * 100) + "%" : ""}
              </strong>
              &nbsp;
              {Math.abs(listingData.price_change) > 0  ? (listingData.data().price_change_pct >= 0 ? "increase" : "decrease"): ""}
            </Typography>
          }
        />)}

        {listingData && (
          <StatisticsCard

            key={"price_changes"}
            value={
              friendlyTimeFormat(listingData.data().last_change_dt)
            }
            title={"Since last price change"}
            icon={React.createElement(CalendarDaysIcon, {
              className: "w-6 h-6 text-white",
            })}
            footer={
              <Typography className="font-normal text-sm text-blue-gray-600">
                <strong>
                  {listingData.data().no_price_changes}
                </strong>
                &nbsp;
                {(listingData.data().no_price_changes > 1) || (listingData.data().no_price_changes === 0)
                  ? "price changes"
                  : "price change"}
              </Typography>
            }
          />
        )}
      </div>

      {/* <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
          {statisticsChartsData.map((props) => (
            <StatisticsChart
              key={props.title}
              {...props}
              footer={
                <Typography
                  variant="small"
                  className="flex items-center font-normal text-blue-gray-600"
                >
                  <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                  &nbsp;{props.footer}
                </Typography>
              }
            />
          ))}
        </div> */}
      <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3">
        <Card className="overflow-hidden xl:col-span-2">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 flex items-center justify-between p-6"
          >
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-1">
                Price History
              </Typography>
              <Typography
                variant="small"
                className="flex items-center gap-1 font-normal text-blue-gray-600"
              >
                <CheckIcon strokeWidth={3} className="h-4 w-4 text-blue-500" />
                <strong>
                  {listingScrapesData && listingScrapesData.docs.length}
                </strong>
                scrapes
              </Typography>
            </div>
          </CardHeader>
          <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            <table className="w-full min-w-[640px] table-auto">
              <thead>
                <tr>
                  {["date", "price"].map((el) => (
                    <th
                      key={el}
                      className="border-b border-blue-gray-50 py-3 px-6 text-left"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-medium uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listingScrapesData &&
                  listingScrapesData.docs.map((doc, key) => {
                    const className = `py-3 px-5 ${
                      key === projectsTableData.length - 1
                        ? ""
                        : "border-b border-blue-gray-50"
                    }`;

                    return (
                      <tr key={key}>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {formatFirebaseTimestamp(doc.data().scrapeTs)}
                            </Typography>
                          </div>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatPrice(doc.data().price, doc.data().currency)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </CardBody>
        </Card>
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 p-6"
          >
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Price Changes
            </Typography>
            
          </CardHeader>
          {listingScrapesData && 
          <CardBody className="pt-0">
           {
              getUniquePriceHistoryChanges(listingScrapesData.docs).map(
                ({ price_usd, currency, scrapeTs, change }, key) => (
                  <div key={key} className="flex items-start gap-4 py-3">
                    <div
                      className={`relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] ${
                        key === ordersOverviewData.length - 1
                          ? "after:h-0"
                          : "after:h-4/6"
                      }`}
                    >
                      {/* {React.createElement(icon, {
                        className: `!w-5 !h-5 ${color}`,
                      })} */}
                      {change === 1 && (
                        <ArrowUpIcon
                          strokeWidth={3}
                          className="h-5 w-5 text-green-500"
                        />
                      )}
                      {change === -1 && (
                        <ArrowDownIcon
                          strokeWidth={5}
                          className="h-5 w-5 text-red-500"
                        />
                      )}
                      {change === 0 && (
                        <TicketIcon
                          strokeWidth={5}
                          className="h-5 w-5 text-green-500"
                        />
                      )}
                    </div>
                    <div>
                      <Typography

                        color="blue-gray"
                        className="block font-medium text-lg"
                      >
                        {formatPrice(price_usd, currency)}
                      </Typography>
                      <Typography
                        as="span"

                        className="text-md font-medium text-blue-gray-500"
                      >
                        {formatFirebaseTimestamp(scrapeTs)}
                      </Typography>
                    </div>
                  </div>
                )
                      )}
          </CardBody>}
        </Card>
      </div>
    </div>
  );
};

export default ListingDetails;
