import {
  collectionGroup,
  query,
  getDocs,
  collection,
  orderBy
} from "firebase/firestore";
import { createContext, useState, useEffect} from "react";
import db from "../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { UserAuth } from "./AuthContext";

export const DataContext = createContext();

async function getData(dataName) {
  const ref = collectionGroup(db, dataName);
  const q = query(ref);
  return await getDocs(q);
}

export function DataContextProvider({ children }) {

  // const queryConfigData = query(collection(db, "config"));
  // const [configDataValue] = useCollection(queryConfigData, options);
  // const getConfigData = () => configDataValue;

  const { user } = UserAuth();

  console.log(`USER ID: ${user ? user.uid : "user not logged in"}`)

  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const col = "user_data";
  const userDataQuery = query(collection(db, `${col}/${user.uid}/dPanels`), orderBy("created"));
  const [userData] = useCollection(userDataQuery, options);

  const [categoryData, setCategoryData] = useState({});
  const [manufacturerData, setManufacturerData] = useState({});
  const [modelData, setModelData] = useState({});
  const [categoryIdToFormattedName, setCategoryIdToFormattedName] = useState({});
  const [modelIdToFormattedName, setModelIdToFormattedName] = useState({});
  const [manufactureIdToFormattedName, setManufacturerIdToFormattedName] = useState({});

  const [selectedCategory, setSelectedCategory] = useState({label: "All Categories", value: 'All Categories'});
  const [selectedManufacturer, setSelectedManufacturer] = useState({label: "All Manufacturers", value: "All Manufacturers"});
  const [selectedModel, setSelectedModel] = useState({label: "All Models", value: "All Models"});


  useEffect(() => {

    const categoryDataObj = {};
    const categoryIdToFriendlyNameObj = {};

    getData("category_data").then((snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const id = doc.id;
        const formattedName = doc.data().formattedName ? doc.data().formattedName.trim() : `[${id}]`;
        categoryIdToFriendlyNameObj[id] = formattedName;
        if (formattedName){
          if (categoryDataObj[formattedName]){
            categoryDataObj[formattedName].ids.push(id);
            categoryDataObj[formattedName].models.concat(doc.data().model);
          } else {
            categoryDataObj[formattedName] = {selected: false, ids: [id], models: doc.data().model};
          }
        }
      }
      console.log("Category Data: ", categoryDataObj);
      setCategoryIdToFormattedName(categoryIdToFriendlyNameObj);
      setCategoryData(categoryDataObj);
    });

    const manufacturerDataObj = {};
    const manufacturerIdToFormattedNameObj = {};
     getData("manufacturer_data").then((snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const id = doc.id;
        const formattedName = doc.data().formattedName ? doc.data().formattedName.trim() : `[${id}]`;
        manufacturerIdToFormattedNameObj[id] = formattedName;
        if (formattedName){
          if (manufacturerDataObj[formattedName]){
            manufacturerDataObj[formattedName].ids.push(id);
            manufacturerDataObj[formattedName].categories.concat(doc.data().category);
          } else {
            manufacturerDataObj[formattedName] = {selected: false, ids: [id], categories: doc.data().category ? doc.data().category : [] };
          }
        }
      }
      console.log("Manufacturer Data: ", manufacturerDataObj);
      setManufacturerIdToFormattedName(manufacturerIdToFormattedNameObj);
      setManufacturerData(manufacturerDataObj);
    });

    const modelDataObj = {};
    const modelToFriendlyNameObj = {};
    getData("model_data").then((snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const id = doc.id;
        const formattedName = doc.data().formattedName ? doc.data().formattedName.trim() : null;

        // temp hack until we make model names unique to manufacturers
        if (!formattedName){
          continue;
        }

        modelToFriendlyNameObj[id] = formattedName;
        if (formattedName){
          if (modelDataObj[formattedName]){
            modelDataObj[formattedName].ids.push(id);
            modelDataObj[formattedName].manufacturers.concat(doc.data().manufacturer);
            modelDataObj[formattedName].categories.concat(doc.data().category);
          } else {
            modelDataObj[formattedName] = {selected: false, ids: [id], manufacturers: doc.data().manufacturer, categories: doc.data().category};
          }
        }
      }
      console.log("Model Data: ", modelDataObj);
      setModelIdToFormattedName(modelToFriendlyNameObj);
      setModelData(modelDataObj);
    });

  }, []);

  const getCategoryData = () => categoryData;
  const getModelData = () => modelData;
  const getManufacturerData = () => manufacturerData;

  const getCategoryNameFromId = (id) => categoryIdToFormattedName[id] ? categoryIdToFormattedName[id] : `UNKNOWN_CATEGORY (${id})`;
  const getModelNameFromId = (id) => modelIdToFormattedName[id] ? modelIdToFormattedName[id] : `UNKNOWN_MODEL (${id})`;
  const getManufacturerNameFromId = (id) => manufactureIdToFormattedName[id] ? manufactureIdToFormattedName[id] : `UNKNOWN_MANUFACTURER (${id})`;

  const getConstraintState = () =>{
    return {
      selectedCategory, setSelectedCategory,
      selectedManufacturer, setSelectedManufacturer,
      selectedModel, setSelectedModel
    }
  }

  const getUserData = () => userData;

  return (
    <DataContext.Provider
      value={{
        getCategoryData,
        getModelData,
        getManufacturerData,
        getCategoryNameFromId,
        getModelNameFromId,
        getManufacturerNameFromId,
        getConstraintState,
        getUserData
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

// export const AppData = () => {
//   return useContext(DataContext);
// };
