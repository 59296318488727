import React, { useState, useEffect} from 'react';
import {
  collection, orderBy, query, doc, limit,
} from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

import './Lister.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import db from '../firebase';
import {formatFirebaseTimestamp} from '../utils';

function Lister(props) {
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const [manufacturer, setManufacturer] = useState(props.default);
  const [selectedScrapeId, setSelectedScrapeId] = useState('EMPTY');

  function handleChange(event) {
    setManufacturer(event.target.value);
  }

  // function investigateBadParse(scrapeId, keyword, pageId) {
  //   func({ keyword, pageId, scrapeId });
  // }

  const q = query(
    collection(db, `${props.listingSite}/${manufacturer}/${props.scrapesCollection}`),
    orderBy('started', 'desc'),
    limit(2),
  );
  const [value, loading, error] = useCollection(q, options);
  
  const queryString = `planecheck_listings/${manufacturer}/${props.scrapesCollection}/${selectedScrapeId}/listing_pages_progress_fast`;
  const queryStringScrapeData = `planecheck_listings/${manufacturer}/${props.scrapesCollection}/${selectedScrapeId}/listing_scrape_data`;

  const listing_pages_query = query(
    collection(db,queryString ),
    orderBy('started','desc')
   );

   const listing_scrape_data_query = query(
    collection(db,queryStringScrapeData ),
    orderBy('started', 'desc'),
   );

  const [listingPagesValue, listingPagesLoading, listingPagesEerror] = useCollection(listing_pages_query, options);
  const [listingScrapeDataValue, listingScrapeDataLoading, listingScrapeDataEerror] = useCollection(listing_scrape_data_query, options);
  
  const functions = getFunctions();
  
  const qc = query(doc(db, `config/${props.configName}`));
  const [config] = useDocument(qc, options);

  if (window.location.href.indexOf('localhost') > -1) {
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  }

  // const [progressValues, setProgressValue] = useState({})

  useEffect(() => {

    if (value){
      setSelectedScrapeId(value.docs[0].id);
    }

  }, [value]);

  // useEffect(() => {

  //   async function getProgress(scrapeId) {
  //     const ref = collection(db, `${props.listingSite}/${manufacturer}/${props.scrapesCollection}/${scrapeId}/progress`);
  //     const scrapes = query(ref);

  //     const s = await getDocs(scrapes);
  //     return s;
  //   }

  //   if (!value){
  //     console.log('no value')
  //     return 
  //   } else{
  //     console.log("new value", value)
  //   }

  //   value.forEach((v)=>{

  //     console.log("scrape id", v.id)

  //     const progressValues ={};
  //     getProgress(v.id)
  //       .then((p)=>{
  //         console.log(p.docs)
  //         p.docs.forEach((page)=>{
  //           console.log(page.id, page.data())
  //           if (progressValues[v.id]){
  //             progressValues[v.id][page.id] = page.data().count;
  //           } else {
  //             progressValues[v.id] = {}
  //             progressValues[v.id][page.id] = page.data().count;
  //           }
  //         })
  //         console.log(progressValues)
  //         setProgressValue(progressValues);
  //       })
  //   })



  // }, [value, manufacturer, props.listingSite, props.scrapesCollection]);

  const scrapedListingCount = (dat) => {
    
    if (!dat) return 0;
    if (dat.totalCounter) return dat.totalCounter;
    if (!dat.totalListingCountBreakdown) return 0;
    const sum = Object.values(dat.totalListingCountBreakdown).reduce((a, b) => a + b, 0);
    return sum;
  };

  const percentageProgress = (dat) => {
    const sum = scrapedListingCount(dat);
    return Math.round(sum / dat.initialListingCount * 100);
  };

  const timeTakenForPage = (doc) => {

    if (!doc.data().updated || !doc.data().started){
      return "N/A";
    } 
    const diff = doc.data().updated.toDate() - doc.data().started.toDate();

    return (diff /1000/60).toFixed(1) + " m";
    
  };

  const timeForDetailsPage = (doc) => {

    if (!doc.data().updated || !doc.data().started){
      return "N/A";
    } 
    const diff = doc.data().updated.toDate() - doc.data().started.toDate();

    return (diff /1000).toFixed(1) + " s";
    
  };

  const statusForPage = (doc) => {

    if (doc.data().succeeded && doc.data().noListings){
      if (doc.data().noListings === (doc.data().listingFailureCount + doc.data().succeeded)){
        return `[ ${doc.data().listingFailureCount} failed / ${doc.data().succeeded}]`
      }
      return doc.data().succeeded === doc.data().noListings ? "Success" : `[ ${doc.data().noListings - doc.data().succeeded} TODO ]`;
    } 
    if (doc.data().started){
      return "*In progress...*";
    }

  }

  const totalTimeTaken = (doc) => {
    if (!doc.data().updated || !doc.data().started){
      return "N/A";
    } 
    const diff = doc.data().updated.toDate() - doc.data().started.toDate();
    return (diff /1000/60).toFixed(1) + " min";
  };

  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
    setSelectedScrapeId()
  }

  // const func = httpsCallable(functions, 'testBadParse');

  // const getProgress = (p) => {
  //   console.log("progress",p)
  //   return JSON.stringify(p)

  // }

  const timingInfo = (page, data) =>{

    if (data){


      const pageNo = page.replace('page','');
      const pageId = `page_${pageNo}`;
      const pageTiming = data.timing[pageId];
      
      const started = pageTiming.started;
      const completed = pageTiming.completed;

      const diff  = completed.toDate() - started.toDate();
      const minDiff = (diff /1000/60).toFixed(1) + " m";

      
      const start = formatFirebaseTimestamp(started);
      const comp = formatFirebaseTimestamp(completed);

      return ` ${start} [   ${minDiff}  ]Page atempts ${pageTiming.page_attempts} ----> ${comp}`;
    } 
    return `loading...`;

  }

  return (
    <div>
      <div>
        {/* <
         onClick={getControllerUrls}>Get Controller Listing URLs</button> */}
        {/* <span>Document: {config && JSON.stringify(config.data().keywords)}</span> */}
        {/* <div>
                <Caller functionName="enqueueControllerGetListingPagesSingleCall" text={`Enque GLP`} params={{keyword:manufacturer }}></Caller>
            </div>  */}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Manufacturer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={manufacturer}
            label="Manufacturer"
            onChange={handleChange}
          >
            {value
              && config.data().keywords.map((item, i) => (
                <MenuItem key={`key${i}`} value={item.toLowerCase()}>
                  {config.data().names[i]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>



        {error && (
        <strong>
          Error:
          {JSON.stringify(error)}
        </strong>
        )}
        {loading && <span>Collection: Loading...</span>}
        {value
          && value.docs.map((doc, index) => (
            <Accordion open={open === index} key={doc.id}>
              <AccordionHeader onClick={() => handleOpen(index, doc.id)}>
                [
                {percentageProgress(doc.data())}
                %]
                {' '}
                {formatFirebaseTimestamp(doc.data().started)}
                {' '}
                [
                {scrapedListingCount(doc.data())}
                ] *
                {doc.data().errors && Object.keys(doc.data().errors).length}
                *
                {totalTimeTaken(doc)}
              </AccordionHeader>
              <AccordionBody>

                <div>
                  Scrape ID:
                  {doc.id}
                </div>
                <div>
                  Status:
                  {doc.data().status }
                </div>
                
                <div>
                  Listing Detail Pages Progress:
                  {doc.data().progress && Object.keys(doc.data().progress).sort().map((p)=>(<div key={p}>{p}:  {doc.data().progress[p]}</div>)) }
                </div>
                <div>
                  Pages:
                  {' '}
                  {(doc.data().progress && Object.keys(doc.data().progress).length)
                    || 0}
                </div>
                <div>
                  Initial Page Count:
                  {doc.data().pageCount}
                </div>
               
                
                <div>
                  Scraped count (inc):
                  {scrapedListingCount(doc.data())}
                </div>
                <div>
                  Initial Listings:
                  {doc.data().initialListingCount}
                </div>
                <div>
                <strong>Listing Page Progress:</strong>
                {listingPagesLoading && <span>Collection: Loading...</span>}
                {listingPagesEerror && <span>ERROR...</span>}
                  {listingPagesValue 
                  && listingPagesValue.docs.map((doc, index) => (
                    <p>{doc.id} [{doc.data().pageStatus}]:: {statusForPage(doc)} :: SUCCEEDED[{doc.data().succeeded}] :: FAILED[{doc.data().listingFailureCount}]:: TOTAL ATTEMPTS {doc.data().totalAttempts} :: STARTED [{doc.data().listingAttempts}]  [Time taken {timeTakenForPage(doc)}]] :: {doc.data().proxy_used} </p>
                  ))}
                </div>
                <div>
                <strong>Listing Details Progress:</strong>
                {listingScrapeDataEerror && <span>ERROR</span>}
                {listingScrapeDataLoading && <span>Collection: Loading...</span>}
                  {listingScrapeDataValue 
                  && listingScrapeDataValue.docs.map((doc, index) => (
                    <p>{doc.id} :: {doc.data().strategy}  :: TIME: { timeForDetailsPage(doc)} :: [PROXY {doc.data().proxy}]</p>
                  ))}
                </div>
                <div>
                  <strong>Updated Listings:</strong>
                  {doc.data().updatedListingCount}
                </div>
                {doc.data().errors && Object.keys(doc.data().errors).map((key) => (
                  <div key={key}>
                    {key}
                    {' '}
                    =++
                    {' '}
                    {doc.data().errors[key]}
                    {' '}
                  </div>
                ))}
                {doc.data().pages && Object.keys(doc.data().pages).map((key, index) => (
                  <div key={index}>
                    {key}
                    {' '}
                    ::
                    {' '}
                    {doc.data().pages[key] && doc.data().pages[key].data && doc.data().pages[key].data.length}
                    {' '}
                    ::  
                    {timingInfo(key, doc.data())}
                    {/* <button className="border p-1 bg-blue-300 text-white" onClick={(e) => investigateBadParse(doc.id, manufacturer, `page${index + 1}`)}>Investigate</button> */}
                    {' '}
                  </div>
                ))}
              </AccordionBody>
            </Accordion>
          ))}
      </div>
          
    </div>
  );
}

export default Lister;
