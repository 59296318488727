import Caller from "../../components/Caller";

const Testing = () => {
  

  return (
    <div className="max-w-[600px] mx-auto my-16 p-4">
      <h1 className="text-2xl font-bold py-4">Testing</h1>

      <div>
        <Caller text="Get Planecheck No. Pages" functionName="getPlanecheckNoPages"></Caller>
      </div>
      <div>
        <Caller text="Get Planecheck Listing Summary" functionName="testPlanecheckPagesNew"></Caller>
      </div>

       {/* <div>
        <Caller text="Test Plancheck parse" functionName="testPlanecheckParse"></Caller>
      </div>

      <div>
        <Caller text="Test Controller JSON Parse" functionName="testControllerJSON"></Caller>
      </div>
      <div>
        <Caller text="Test get Controller Listing Data" functionName="testGetControllerListingDataMain"></Caller>
      </div>
      <div>
        <Caller text="Delete Controller Listing Data" functionName="deleteControllerListings"></Caller>
      </div> 
      <div>
        <Caller text="Controller Analysis 1" functionName="controllerAnalysis1"></Caller>
      </div>
      <div>
        <Caller text="Local Test Al Scrape Add" functionName="writeTestAllScrape"></Caller>
      </div>
      <div>
        <Caller text="Recalc Aggregation" functionName="recalcListingAggregation"></Caller>
      </div>
      <div>
        <Caller text="Test delisting" functionName="testDelisting"></Caller>
      </div>
      <div>
        <Caller text="Scrape Comparison" functionName="testScrapeComparison"></Caller>
      </div>    
      <div>
        <Caller text="CG Test Query" functionName="testCollectionGroupQueries"></Caller>
      </div>    
      <div>
        <Caller text="Test Last Scrape For Keyword" functionName="reprocessAllScrape"></Caller>
      </div>      */}
    </div>
  );
};
export default Testing;
