import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Dialog,
  CardFooter,
  Input,
} from "@material-tailwind/react";

import {
  and,
  collection,
  limit,
  orderBy,
  doc,
  query,
  startAfter,
  updateDoc,
  endBefore,
  limitToLast,
  where,
  getCountFromServer,
} from "firebase/firestore";
import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
// import StatisticsCard from "widgets/cards/statistics-card";
// import { Chip  } from "@material-tailwind/react";

import db from "../../firebase";
// import { ContextMenu } from "../../components/ContextMenu";
import {
  connectFunctionsEmulator,
  getFunctions,
  // httpsCallable,
} from "firebase/functions";
// import { Link, useSearchParams } from "react-router-dom";
import { Link} from "react-router-dom";

import {
  formatPrice,
  formatSource,
  friendlyTimeFormat,
  firendlyCategoryName,
  friendlyModelName,
  friendyManufacturerName
} from "../../utils";
import {
  useCollection,
} from "react-firebase-hooks/firestore";
// import DatePicker from "react-datepicker";
// import { Spinner } from "@material-tailwind/react";

import "react-datepicker/dist/react-datepicker.css";
import ConstraintSelector from "./ConstraintSelector";
import { DataContext } from "../../context/DataContext";

import { Button } from "react-bootstrap";
import { DefaultPagination } from "../../components/Pagination";
// import SourceSelector from "./SourceSelector";

const functions = getFunctions();
// console.log("me " + window.location.href);

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export function AllListings() {

  const dataContext = useContext(DataContext);
  // const [sources, setSources] = useState({});
  const [sources] = useState({});
  // const [page] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [listingsCount, setListingCount] = useState();

  const handleOpen = (data) => {
    setOpen(true);
    setSelectedEdit(data);
  };

  const [editYear, setEditYear] = useState("-");
  const [editModel, setEditModel] = useState("-");
  const [editCategory, setEditCategory] = useState("-");
  
  const handleQuit = () =>{
    clear();
  }


  const handleClose = async () => {
    console.log(`closing with: `, editYear, editModel, editCategory);
    if (!selectedEdit){
      return ;
    }
    try {
      const uniqueListingId = selectedEdit.uniqueListingId;
      console.log(`update for ${uniqueListingId}`)
      const docRef = doc(db, "listing_aggregations_v1", uniqueListingId);

      await updateDoc(docRef, {
        year: editYear.trim(),
        model: editModel.trim(),
        category: editCategory.trim()
      });

      console.log("Document written with ID: ", docRef.id);

    } catch (e) {
      console.error("Error adding document: ", e);
    }
    clear();

  }

  const clear = ()=>{
    setSelectedEdit(null);
    setEditYear("-");
    setEditModel("-");
    setEditCategory("-");
    setOpen(false);
  }

  const updateYear = (e) =>{

    console.log(`setting year ${e.target.value}`)
    setEditYear(e.target.value);
  }

  const updateModel = (e) =>{
    setEditModel(e.target.value);
  }

  const updateCategory = (e) =>{
    setEditCategory(e.target.value);
  }


  // const [startDate, setStartDate] = useState(new Date());
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  // const [searchParams, setSearchParams] = useSearchParams();

  // if (searchParams.has("scs")) {
  //   if (JSON.stringify(sources) !== searchParams.get("scs")) {
  //     setSources(JSON.parse(searchParams.get("scs")));
  //   }
  // }

  // if (searchParams.has("m")) {
  //   if (JSON.stringify(manufacturers) !== searchParams.get("m")) {
  //     setManufacturers(JSON.parse(searchParams.get("m")));
  //   }
  // }

  
  // if (searchParams.has("dt")) {
  //   // console.log(startDate.toISOString().slice(0,10), searchParams.get('dt'))
  //   if (startDate.toISOString().slice(0, 10) !== searchParams.get("dt")) {
  //     const newDt = new Date(searchParams.get("dt"));
  //     newDt.setUTCHours(0, 0, 0, 0);
  //     setStartDate(newDt);
  //   }
  // } else {
    // console.log('no date')
  // }
  
  let sourceConstraints = [];
  Object.keys(sources).forEach((s)=>{
    if (sources[s].selected){
      sourceConstraints = sourceConstraints.concat(s);
    }
  })

  const COLLECTION = "listing_aggregations_v1";

  const selectedModel = dataContext.getConstraintState().selectedModel;
  const selectedManufacturer = dataContext.getConstraintState().selectedManufacturer;
  const selectedCategory = dataContext.getConstraintState().selectedCategory;

  const wheres = [];
  if (selectedModel.value && selectedModel.value.ids && selectedModel.value.ids ){
    wheres.push(where("model", "in", selectedModel.value.ids))
  }
  if (selectedManufacturer.value && selectedManufacturer.value.ids && selectedManufacturer.value.ids.length > 0){
    wheres.push(where("manufacturer", "in", selectedManufacturer.value.ids))
  }
  if (selectedCategory.value && selectedCategory.value.ids && selectedCategory.value.ids.length > 0){
    wheres.push(where("category", "in", selectedCategory.value.ids))
  }

  const docCursors = useRef()
  const listingsPerPage = 25;
  const paginationConstraints =[];

  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      paginationConstraints.push(startAfter(docCursors.current.docToStartAfter))
      paginationConstraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      paginationConstraints.push(endBefore(docCursors.current.docToEndBefore))
      paginationConstraints.push(limitToLast(listingsPerPage));
    }
  } else {
    paginationConstraints.push(limit(listingsPerPage));
  }

  const pageConstraints = []

  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      pageConstraints.push(startAfter(docCursors.current.docToStartAfter))
      pageConstraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      pageConstraints.push(endBefore(docCursors.current.docToEndBefore))
      pageConstraints.push(limitToLast(listingsPerPage));
    }
  } else {
    pageConstraints.push(limit(listingsPerPage));
  }

  const queryNewListingsData = query(
    collection(db, COLLECTION),
    and(...wheres),
    orderBy("first_seen", "desc"),
    ...pageConstraints
  );

  const queryNewListingsDataNoLimit = query(
    collection(db, COLLECTION),
    and(...wheres),
    orderBy("first_seen", "desc"),
  );

  const [listingsValue] = useCollection(queryNewListingsData, options);

  const handeOnChange = ()=>{
    setPage(1);
  }
  
  const handlePageChange = useCallback((nextPage)=>{
    if (listingsValue){
      setPage((page)=>{
        // console.log(`current page is ${page}, next page is ${nextPage}`)

        if (nextPage < page){
          // console.log('setting doc to end before ', listingsValue.docs[0])
          docCursors.current = { direction: "back", docToEndBefore: listingsValue.docs[0]};
        } else {
          // console.log('setting doc to start after ',  listingsValue.docs[listingsValue.docs.length-1])
          docCursors.current = { direction: "forward", docToStartAfter: listingsValue.docs[listingsValue.docs.length-1]};
        }
        // console.log('doc Cursors', docCursors)
        return nextPage;
      })
    }
  }, [listingsValue])

  useEffect(()=>{
    getCountFromServer(queryNewListingsDataNoLimit)
    .then((snapshot)=>{
      console.log(`got count for query without limit:${snapshot.data().count}`)
      setListingCount(snapshot.data().count);
    })
  }, [queryNewListingsDataNoLimit])

  // const doSetDateSearchParams = (searchParams, val) => {
  //   const toSet = val.toISOString().slice(0, 10);
  //   // console.log('setting', toSet, val);
  //   searchParams.set("dt", toSet);
  //   setSearchParams(searchParams);
  // };

  // const sourceItems = Object.keys(sources).map((key, index) => {
  //   if (sources[key].selected){
  //     return <Chip key={`source_chip_${key}`} variant="gradient" value={key} onClose={() => clearSource(key)} />
  //   }
  //   return null;
  // });

  // const manufacturerItems = Object.keys(manufacturersReverseLookup).map((key, index) => {
  //   if (manufacturersReverseLookup[key].selected){
  //     return <Chip key={`manufacturer_chip_${key}`} variant="gradient" value={key} onClose={() => clearManufacturer(key)} />
  //   }
  //   return null;
  // });

  // const categoryItems = Object.keys(categoryReverseLookup).map((key, index) => {
  //   if (categoryReverseLookup[key].selected){
  //     return <Chip key={`category_chip_${key}`} variant="gradient" value={key} onClose={() => clearCategory(key)} />
  //   }
  //   return null;
  // });

  // const modelItems = Object.keys(modelsReverseLookup).map((key, index) => {
  //   if (modelsReverseLookup[key].selected){
  //     return <Chip key={`model_chip_${key}`} variant="gradient" value={key} onClose={() => clearModel(key)} />
  //   }
  //   return null;
  // });

  return (
    <div className="mt-12">
      <div>
        {/* Date:{" "}
        <DatePicker
          todayButton="Vandaag"
          showIcon
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            doSetDateSearchParams(searchParams, date);
          }}
        /> */}
      </div>
      
      {/* <div className="flex gap-2">
       Sources: {sourceItems}
      </div> */}
      {/* <div className="flex gap-2">
        Categories: {categoryItems}
      </div>
      <div className="flex gap-2">
        Manufacturers{manufacturerItems}
      </div>

      <div className="flex gap-2">
        Models:{modelItems}
      </div> */}

      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        outsidePress={()=>handleQuit}
        escapeKey={()=>handleQuit}
        className="bg-transparent shadow-none backdrop:none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            
          <Typography variant="h4" color="blue-gray">
              {selectedEdit && selectedEdit.reg}
            </Typography>
            <Typography variant="h4" color="blue-gray">
              {selectedEdit && selectedEdit.title}
            </Typography>

            <Typography variant="h4" color="blue-gray">
              {selectedEdit && selectedEdit.source}
            </Typography>

            <Typography className="-mb-2" variant="h6">
              Year
            </Typography>
            [Current: {selectedEdit && selectedEdit.year}]
            <Input label="Year" size="lg" value={editYear} onChange={(e)=>updateYear(e)}/>
            <Typography className="-mb-2" variant="h6">
              Model
            </Typography>
            [Current: {selectedEdit && selectedEdit.model}]
            <Input label="Model" size="lg" value={editModel} onChange={(e) => updateModel(e)}/>
            <Typography className="-mb-2" variant="h6">
              Category
            </Typography>
            [Current: {selectedEdit && selectedEdit.category}]
            <Input label="Model" size="lg" value={editCategory} onChange={(e) => updateCategory(e)}/>
          </CardBody>
          <CardFooter className="pt-0">
            <Button className="border p-1 bg-blue-300 text-white" variant="gradient" onClick={(e)=> handleClose()} fullWidth>
              Save
            </Button>
            <Button
            variant="text"
            color="red"
            onClick={()=>handleQuit()}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          </CardFooter>
        </Card>
      </Dialog>

      <div className="flex gap-2">
          
          <ConstraintSelector onChange={handeOnChange}/>
          {listingsValue &&<DefaultPagination page={page} handlePageChange={handlePageChange} listingsCount={listingsCount} listingsPerPage={listingsPerPage}></DefaultPagination>}

        </div>
         
        <div className="flex gap-3 items-center">
    </div>
          
        {/* <div className="flex gap-2">
          <div>{selectedCategory.label} // {selectedManufacturer.label} // {selectedModel.label}</div>
        </div> */}

        {/* <div className="flex gap-2">
          <div>{JSON.stringify(selectedCategory.value.ids)} // {JSON.stringify(selectedManufacturer.value.ids)} // {JSON.stringify(selectedModel.value.ids)}</div>
        </div> */}

      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            <div className="flow-root">
              <Typography variant="h6" color="white" className="float-left">
                New ({listingsValue && listingsValue.docs.length > 0 && listingsValue.docs.length })
              </Typography>
            </div>
          </CardHeader>
          <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            <table className="w-full min-w-[640px] table-auto">
              <thead>
                <tr>
                  {[
                    "Title",
                    "price",
                    "year",
                    "category",
                    // "Unformatted Model",
                    "model",
                    "Manufacturer",
                    // "listing ID",
                    "source",
                    "first seen",
                    ""
                  ].map((el) => (
                    <th 
                      key={el}
                      className="border-b border-blue-gray-50 py-3 px-5 text-left"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listingsValue &&
                  listingsValue.docs.map((doc, index) => {
                    const className = `py-3 px-5 ${
                      index === listingsValue.docs.length - 1
                        ? ""
                        : "border-b border-blue-gray-50"
                    }`;

                    return (
                      <tr key={`row${index}`}>
                        <td className={className}>
                            {/* <Avatar
                              src={doc.data().imageURL}
                              alt={index}
                              size="sm"
                            /> */}
                            <Link className="flex items-center gap-4"
                              to={`/dashboard/listing-details/${
                                doc.data().uniqueListingId
                              }`}
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {doc.data().title}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className=""
                              >
                                {doc.data().reg}
                              </Typography>
                            </Link>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatPrice(doc.data().price, doc.data().currency)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {doc.data().year}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {firendlyCategoryName(dataContext,doc.data().category)}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendlyModelName(dataContext, doc.data().model)}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendyManufacturerName(dataContext, doc.data().manufacturer)}
                          </Typography>
                        </td>
                        {/* <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {doc.data().listingId}
                          </Typography>
                        </td> */}
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatSource(doc.data().source)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendlyTimeFormat(doc.data().first_seen)}
                          </Typography>
                        </td>
                        {/* <td className={className}>
                        <Button key={`edit_${doc.id}`} className="border p-1 bg-blue-300 text-white" onClick={()=>handleOpen(doc.data())}>Edit</Button>

                        </td> */}


                        {/* <td className={className}>
                          <ContextMenu
                            menuItems={menuItems}
                            data={doc.data()}
                          />
                        </td> */}
                        {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatFirebaseTimestamp(doc.data().scrapeTs)}
                        </Typography>
                      </td> */}
                        {/*<td className={className}>
                        <div className="w-10/12">
                          <Typography
                            variant="small"
                            className="mb-1 block text-xs font-medium text-blue-gray-600"
                          >
                            {completion}%
                          </Typography>
                          <Progress
                            value={completion}
                            variant="gradient"
                            color={completion === 100 ? "green" : "blue"}
                            className="h-1"
                          />
                        </div>
                      </td>
                      <td className={className}>
                        <Typography
                          as="a"
                          href="#"
                          className="text-xs font-semibold text-blue-gray-600"
                        >
                          <EllipsisVerticalIcon
                            strokeWidth={2}
                            className="h-5 w-5 text-inherit"
                          />
                        </Typography>
                      </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>

    </div>
  );
}

export default AllListings;
