import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export function DefaultPagination({
  page,
  handlePageChange,
  listingsCount,
  listingsPerPage,
}) {
  const totalPages = Math.ceil(listingsCount / listingsPerPage);

  const next = () => {
    if (page === totalPages) return;
    handlePageChange(page + 1);
  };

  const prev = () => {
    if (page === 1) return;
    handlePageChange(page - 1);
  };

  return (
    <div className="flex items-center gap-4">
      <IconButton
        size="sm"
        variant="outlined"
        onClick={prev}
        disabled={page === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
      <div className="flex items-center gap-2">
      <Typography color="gray" className="font-normal">
        Page <strong className="text-gray-900">{page}</strong> of{" "}
        <strong className="text-gray-900">{totalPages ? totalPages: "loading..."}</strong> ({listingsCount})
      </Typography>
      </div>
      <IconButton
        size="sm"
        variant="outlined"
        onClick={next}
        disabled={page === totalPages}
      >
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
    </div>
  );
}
