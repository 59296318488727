import { Timestamp } from "firebase/firestore";
import moment from "moment";


function locationFromListingScrapeData(lsData){

  console.log(lsData)

  if (lsData.source === "controller"){

    if (lsData.customData.location){
      return lsData.customData.location.FormattedLocation;
    }
  } else if (lsData.source === "planecheck"){
    return lsData.customData.location ? lsData.customData.location.cityAerodrome : "Unavailable";
      
  } else {
    return "UNKNOWN_SOURCE";
  }

}

function checkIfImageExists(url, callback) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

function daysSinceLastPriceChange(listingAggregationDoc) {
  if (
    typeof listingAggregationDoc.data().last_change_dt ===
    typeof new Timestamp()
  ) {
    return friendlyTimeFormat(listingAggregationDoc.data().last_change_dt);
  } else if (listingAggregationDoc.data().last_change_dt === -1) {
    return "N/A";
  } else {
    if (listingAggregationDoc.data().days_since_price_change === -1) {
      return "N/A";
    }
    return listingAggregationDoc.data().days_since_price_change
      ? listingAggregationDoc.data().days_since_price_change.toFixed(1)
      : "N/A";
  }
}

function firendlyCategoryName (dataContext, categoryId){
  return dataContext.getCategoryNameFromId(categoryId);
}

function friendlyModelName (dataContext, modelId){
  return dataContext.getModelNameFromId(modelId);
}

function friendyManufacturerName(dataContext, manufactureId){
  return dataContext.getManufacturerNameFromId(manufactureId);
}

function friendlyTimeFormatDt(t) {
  let a = moment(new Date(t)).fromNow();
  a = a.replace('minutes',"mins");
  a = a.replace('hours',"hrs");
  a = a.replace('an hour',"1 hr");
  return a;
}

function friendlyTimeFormat(t) {
  // let localeData = moment.updateLocale('en', {
  //     relativeTime: {
  //         future: "in %s",
  //         past: "%s ago",
  //         s: 'Welcome to GeeksForGeeks, a few seconds',
  //         ss: '%d seconds',
  //         m: "a minute",
  //         mm: "%d minutes",
  //         h: "an hour",
  //         hh: "%d hours",
  //         d: "a day",
  //         dd: "%d days",
  //         M: "a month",
  //         MM: "%d months",
  //         y: "a year",
  //         yy: "%d years"
  //     }
  // });
  if (!t.toDate){
    console.log("friendlyTimeFormat NO toDate: ", t);
    return "N/A";
  }
  let a = moment(new Date(t.toDate())).fromNow();
  a = a.replace('minutes',"mins");
  a = a.replace('hours',"hrs");
  a = a.replace('an hour',"1 hr");
  return a;
}

function formatFirebaseTimestamp(t) {
  return moment(new Date(t.toDate()))
    .format("ddd, MMM Do  YYYY HH:mm:ss")
    .toString();
}

function firstCharUpperCase(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function formatSource(s) {
  return firstCharUpperCase(s);
}

function formatKeyword(k) {
  return firstCharUpperCase(k);
}

const NO_PRICE_STR = `Make Offer`

function formatPercentage(p){
  return isNaN(p) ? NO_PRICE_STR : Math.floor(p * 100) + "%"
}

function formatTotalTime(t){
  if (t === -1){
    // this means we could not find the total time for the listing
    return "Unknown"
  }
  return t;
}


function formatPrice(p, ccy) {
  // Create our number formatter.
  try {
    if (isNaN(p)) {

      return NO_PRICE_STR;
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: ccy,
      maximumFractionDigits: 0,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const formattedPrice = formatter.format(p);
    return formattedPrice;
  } catch (e) {
    // console.log(e, ccy)
    if (p) return p.toString();
    return NO_PRICE_STR;
  }
}

function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

function getUniquePriceHistoryChanges(priceHistory, excludeNaN) {

  if (!priceHistory) {
    return [];
  }
  const ret = [];

  for (let i = 0; i < priceHistory.length; i++) {
    const item = priceHistory[i].data();
    // console.log("pr-item", item.price_usd);
    if (item.price_usd || (isNaN(item.price_usd) && !excludeNaN)) {
        let change = NaN;
        if (ret.length > 0) {
            const prevCCy = ret[ret.length - 1].currencyOriginal;
            const prevPrice = ret[ret.length - 1].price;
            // console.log(`ccy ===: ${item.currency} ${prevCCy}`)
            if (item.currency === prevCCy){
              if (item.price !== prevPrice){
                // console.log(`price diff: ${item.price} ${prevPrice}`)
                const prevPriceUSD = ret[ret.length - 1].price_usd;
                if (prevPriceUSD > item.price_usd){
                  change = -1;
                } else if (prevPriceUSD < item.price_usd){
                  change = 1;
                } else if (isNaN(prevPriceUSD) && !isNaN(item.price_usd)) {
                  change = 0;
                } else if (!isNaN(prevPriceUSD) && isNaN(item.price_usd)) {
                  change = 0;
                }
                // console.log('change', change)
              }
            } else {

              const prevPrice = ret[ret.length - 1].price_usd;
                if (prevPrice > item.price_usd){
                  change = -1;
                } else if (prevPrice < item.price_usd){
                  change = 1;
                } else if (isNaN(prevPrice) && !isNaN(item.price_usd)) {
                  change = 0;
                } else if (!isNaN(prevPrice) && isNaN(item.price_usd)) {
                  change = 0;
                }
                // console.log('change', change)
            }
            
        }
        if(ret.length === 0 || (!isNaN(change))){
          ret.push({
            price_usd: item.price_usd,
            price: item.price,
            scrapeTs: item.scrapeTs,
            currency: "USD",
            currencyOriginal: item.currency,
            change: ret.length === 0 ? 0 : change,
          });
        }
    }
  }
  return ret;
}
function formatCompactNumber(number) {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
}
export {
  formatPercentage,
  formatCompactNumber,
  formatFirebaseTimestamp,
  formatTotalTime,
  formatPrice,
  friendlyTimeFormat,
  formatSource,
  formatKeyword,
  getUniquePriceHistoryChanges,
  addHours,
  checkIfImageExists,
  friendlyTimeFormatDt,
  daysSinceLastPriceChange,
  firendlyCategoryName,
  friendlyModelName,
  locationFromListingScrapeData,
  friendyManufacturerName,
  NO_PRICE_STR
};
