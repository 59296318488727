import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  IconButton,
  Button,
  DialogBody,
  DialogFooter,
  Dialog,
} from "@material-tailwind/react";
import { updateDoc, doc, deleteDoc } from "firebase/firestore";
import db from "../../firebase";

import Chart from "react-apexcharts";

import PropTypes from "prop-types";
import { UserAuth } from "../../context/AuthContext";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import ListingsView from "../../pages/dashboard/ListingsView";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import DashboardEditDialog from "./dashboard-edit-dialog";
import { Slider } from "@mui/material";
import { formatCompactNumber } from "../../utils";

export function DashboardCard({ data }) {
  
  const functions = getFunctions();
  
  const defaultAnalysisData = {
    noListings:0,
    maxPrice:0,
    minPrice:0,
    avgPrice:0,
    noPriceCount:0,
    minDaysActive:0,
    maxDaysActive:0,
    avgDaysActive:0,
    noDaysActiveCount:0,
    minYears:0,
    maxYears:0,
    avgYears:0,
    noYearsCount:0,
    minTT:0,
    maxTT:0,
    avgTT:0,
    noTTCount:0,
  }

  const [dialogOpen, setDialogOpen] = useState(false)

  const [analysisData, setAnalysisData] = useState(defaultAnalysisData)

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  const func = httpsCallable(functions, "getDashboardAnalysis");

  async function getDashboardAnalysis() {
   
    console.log(`getting data for panel id ${data.panelId}`, data)

    const response = await func(data);
    console.log(response)
    setAnalysisData(response.data)
  }

  const handleOpen = (data) =>{
    setDialogOpen(true);
  }

  const handleClose = (data) =>{
    setDialogOpen(false);
  }

  useEffect(()=>{
     getDashboardAnalysis()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const doEdit = async(name, manufacturer, models) => { 

    const docRef = doc(db, "user_data", user.uid, "dPanels", data.panelId );
    const manufacturers = [manufacturer];

    const dashPanelObj = {
      name,
      manufacturers,
      models
    }
  
    await updateDoc(docRef, dashPanelObj);
    data = dashPanelObj;
    await getDashboardAnalysis();
  }

  const { user } = UserAuth();

  const deleteDashPanel = async (panelId) => {
    await deleteDoc(doc(db, "user_data", user.uid, "dPanels", panelId))
  }

  const [openEdit, setEditOpen] = React.useState(false);

  const handleEditOpen = (d) => {
    console.log("edit open..")
    console.log("manufacturers are", data.manufacturers);
    console.log("model is:", data.models);
    setEditOpen(true);
  };
  
  const handleEditQuit = () =>{
    console.log(`quitting...`);
    setEditOpen(false);
  }

  const handleSave = async (name, manufacturer, model) => {
    console.log(`Handle Save...`, name, manufacturer, model);
    await doEdit(name, manufacturer, model);
    setEditOpen(false);
  }

  const priceMarks = [
    {
      value: analysisData.minPrice,
      label: formatCompactNumber(analysisData.minPrice)
    },
    {
      value: analysisData.avgPrice,
      label: formatCompactNumber(analysisData.avgPrice)
    },
    {
      value: analysisData.maxPrice,
      label: formatCompactNumber(analysisData.maxPrice)
    }
  ];

  const daysActiveMarks = [
    {
      value: analysisData.minDaysActive,
      label: analysisData.minDaysActive
    },
    {
      value: analysisData.avgDaysActive,
      label: analysisData.avgDaysActive
    },
    {
      value: analysisData.maxDaysActive,
      label: analysisData.maxDaysActive
    }
  ];

  const yearMarks = [
    {
      value: analysisData.minYears,
      label: analysisData.minYears
    },
    {
      value: analysisData.avgYears,
      label: analysisData.avgYears
    },
    {
      value: analysisData.maxYears,
      label: analysisData.maxYears
    }
  ];

  const totalTimeMarks = [
    {
      value: analysisData.minTT,
      label: analysisData.minTT
    },
    {
      value: analysisData.avgTT,
      label: analysisData.avgTT
    },
    {
      value: analysisData.maxTT,
      label: analysisData.maxTT
    }
  ];

  const models = data.models.map((m)=>m.label).join(", ").trim();

  const editDialog = () =>{
    if (data){
      return <DashboardEditDialog
      open={openEdit} 
      handleSave={handleSave}
      handleOpen={handleEditOpen}
      handleQuit={handleEditQuit}
      data={data}
      mode={"edit"}
    />
    }
  }

  const chartConfigDefault = {
    type: "pie",
    width: 80,
    height: 80,
    series: [analysisData.noPriceCount, analysisData.noResults-analysisData.noPriceCount],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#d40f04", "#40a832"],
      legend: {
        show: false,
      },
    },
  };

  const chartConfigPrice = {...chartConfigDefault};
  chartConfigPrice["series"] = [analysisData.noPriceCount, analysisData.noResults-analysisData.noPriceCount]
  
  const chartConfigYears = {...chartConfigDefault};
  chartConfigYears["series"] = [analysisData.noYearsCount, analysisData.noResults-analysisData.noYearsCount]
  
  const chartConfigTT = {...chartConfigDefault};
  chartConfigTT["series"] = [analysisData.noTTCount, analysisData.noResults-analysisData.noTTCount]
  
  const chartConfigDaysActive = {...chartConfigDefault};
  chartConfigDaysActive["series"] = [analysisData.noDaysActiveCount, analysisData.noResults-analysisData.noDaysActiveCount]


  return (
    <>
    {editDialog()}
    <Dialog
        open={dialogOpen}
        size={"xl"}
        handler={handleOpen}
      >
        <DialogBody>
          <ListingsView models={data.models} manufacturer={data.manufacturers[0].value.ids} title={data.name}></ListingsView>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleClose(null)}
            className="mr-1">
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    <Card>
      <CardHeader
        variant="gradient"
        color={"blue"}
        className="mb-4 grid h-10 place-items-center"
      >
        <Typography key={"name"} variant="h6" className="">
          {data.name}
        </Typography>

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={(e) => deleteDashPanel(data.panelId)}
          className="!absolute top-2 right-4 rounded-full w-5 h-5 "
        >
        <TrashIcon
          className="w-5 h-5"
        />
        </IconButton> 

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={(e) => handleEditOpen(e)}
          className="!absolute top-2 left-4 rounded-full w-5 h-5 "
        >
        <PencilSquareIcon
          className="w-5 h-5"
        />
        </IconButton> 

      </CardHeader>
      <CardBody className="p-4">

        <div className="m-5">
          <Typography key={"label-manufacturer"} variant="h4" className="font-normal text-blue-gray-600">
            Manufacturer: {data.manufacturers[0].label}
          </Typography>
          <Typography key={"label-model"} variant="small" className="font-normal text-blue-gray-600">
            Model: {models}
          </Typography>
          <Typography key={"matches"} variant="h4" color="blue-gray">
            Matches: {analysisData.noResults}
          </Typography>
          {data.panelId}

          <div className="grid grid-cols-10 grid-rows-1">
            <div className="col-span-9 px-2">
              <Typography>Price:</Typography>
              <Slider
                value={[analysisData.minPrice, analysisData.avgPrice, analysisData.maxPrice]}
                valueLabelDisplay="auto"
                min={analysisData.minPrice}
                max={analysisData.maxPrice}
                marks={priceMarks}
              />
            </div>
            <div className="col-span-1 justify-center content-center">
              <Chart {...chartConfigPrice} />
            </div>
          </div>    

          <div className="grid grid-cols-10 grid-rows-1">
            <div className="col-span-9 px-2">
            <Typography>Year:</Typography>
            <Slider
              value={[analysisData.minYears, analysisData.avgYears, analysisData.maxYears]}
              valueLabelDisplay="auto"
              min={analysisData.minYears}
              max={analysisData.maxYears}
              marks={yearMarks}
            />
            </div>
            <div className="col-span-1 justify-center content-center">
              <Chart {...chartConfigYears} />
            </div>
          </div>              
          

          <div className="grid grid-cols-10 grid-rows-1">
            <div className="col-span-9 px-2">
            <Typography>Days Active:</Typography>
            <Slider
              value={[analysisData.minDaysActive, analysisData.avgDaysActive, analysisData.maxDaysActive]}
              valueLabelDisplay="auto"
              min={analysisData.minDaysActive}
              max={analysisData.maxDaysActive}
              marks={daysActiveMarks}
            />      
          </div>
            <div className="col-span-1 justify-center content-center">
              <Chart {...chartConfigDaysActive} />
            </div>
          </div>       

          

          <div className="grid grid-cols-10 grid-rows-1">
            <div className="col-span-9 px-2">
            <Typography>Total Time:</Typography>
            <Slider
              value={[analysisData.minTT, analysisData.avgTT, analysisData.maxTT]}
              valueLabelDisplay="auto"
              min={analysisData.minTT}
              max={analysisData.maxTT}
              marks={totalTimeMarks}
            />            
          </div>
            <div className="col-span-1 justify-center content-center">
              <Chart {...chartConfigTT} />
            </div>
          </div>              
        

          
     
      </div>
      <div className="flex justify-end">
          <Button onClick={(e)=>handleOpen(data)}>View Listings</Button>
      </div>

      </CardBody>
        {/* <CardFooter className="border-t">
        </CardFooter> */}
    </Card>
    </>
  );
}

DashboardCard.defaultProps = {
  color: "blue",
  footer: null,
};

DashboardCard.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  footer: PropTypes.node,
};

DashboardCard.displayName = "/src/widgets/cards/dashboard-card.jsx";

export default DashboardCard;
