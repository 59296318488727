import {
  connectFunctionsEmulator,
  getFunctions,
} from "firebase/functions";

import "react-datepicker/dist/react-datepicker.css";
import { DashboardCard } from "../../widgets/cards/dashboard-card";
import { DataContext } from "../../context/DataContext";
import { useContext } from "react";
import DashboardCardPlaceholder from "../../widgets/cards/dashboard-card-placeholder";

const functions = getFunctions();

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export function DashboardPage() {

  const dataContext = useContext(DataContext);
  const userData = dataContext.getUserData();

  // console.log(`user data`, userData)
  
  const dashboardItems = () =>{
    let items = []
    if (userData && userData.docs.length){
      items = userData.docs.map((d)=>{
        const dat = d.data();
        const panelId = d.id;
        return {...dat, panelId}
      })      
    }

    console.log(`new items`, items);

    return items;
  }


  const dashboardItemsLayout = () =>{

    if (!dashboardItems().length){
      return <DashboardCardPlaceholder />
    }
    const elements = dashboardItems().map((d, index)=>{

      return <DashboardCard key={`db-card${d.panelId}`} data={d}/> 
    });
    elements.push(<DashboardCardPlaceholder key={`db-card-placeholder`} />)
    return elements
  }

  return (
    <div className="mt-12">
      <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
        {dashboardItemsLayout()}
      </div>
    </div>
  );
}

export default DashboardPage;
