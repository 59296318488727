import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
// import { ContextMenu } from "../../components/ContextMenu";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  collection,
  endBefore,
  getCountFromServer,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../../firebase";
import { DefaultPagination } from "../../components/Pagination";
import { formatPercentage, formatPrice } from "../../utils";
import { Button } from "react-bootstrap";

export function ReprocessingView() {

  const functions = getFunctions();
  console.log('me ' + window.location.href);

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  const functionName = "cleanPriceAndReprrocessListingAggregation";
  const reprocess = httpsCallable(functions, functionName);


  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const [page, setPage] = useState(1);
  const constraints = [];
  const wheres = [where("cleaned","==",false)];
  
  const queryAggregationsDataNoLimit = query(
    collection(db, "listing_aggregations_to_clean"),
    ...constraints, ...wheres
  );

  const queryCleanedDataNoLimit = query(
    collection(db, "listing_aggregations_to_clean"),
    where("cleaned", "==", "Cleaned!"), 
  );
  
  const listingsPerPage = 25;
  const docCursors = useRef()
  
  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      constraints.push(startAfter(docCursors.current.docToStartAfter))
      constraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      constraints.push(endBefore(docCursors.current.docToEndBefore))
      constraints.push(limitToLast(listingsPerPage));
    }
  } else {
    constraints.push(limit(listingsPerPage));
  }
  
  const queryAggregationsData = query(
    collection(db, "listing_aggregations_to_clean"),
    orderBy('info.maxPrice', "desc"), ...wheres, ...constraints, 
  );

  const [listingsValue] = useCollection(queryAggregationsData, options);
  const [listingsCount, setListingCount] = useState();
  const [cleanedCount, setCleanedCount] = useState();

  useEffect(()=>{
    getCountFromServer(queryCleanedDataNoLimit)
    .then((snapshot)=>{
      console.log(`got cleaned count:${snapshot.data().count}`)
      setCleanedCount(snapshot.data().count);
    })
  }, [queryCleanedDataNoLimit])

  useEffect(()=>{
    getCountFromServer(queryAggregationsDataNoLimit)
    .then((snapshot)=>{
      console.log(`got count for query without limit:${snapshot.data().count}`)
      setListingCount(snapshot.data().count);
    })
  }, [queryAggregationsDataNoLimit])

  const handlePageChange = useCallback((nextPage)=>{
    if (listingsValue){
      setPage((page)=>{
        // console.log(`current page is ${page}, next page is ${nextPage}`)

        if (nextPage < page){
          // console.log('setting doc to end before ', listingsValue.docs[0])
          docCursors.current = { direction: "back", docToEndBefore: listingsValue.docs[0]};
        } else {
          // console.log('setting doc to start after ',  listingsValue.docs[listingsValue.docs.length-1])
          docCursors.current = { direction: "forward", docToStartAfter: listingsValue.docs[listingsValue.docs.length-1]};
        }
        // console.log('doc Cursors', docCursors)
        return nextPage;
      })
    }
  }, [listingsValue])


  const documentId = (doc)=>{
    if (!doc) return "-"
    return doc.id.replaceAll("_FSLASH_","/");
  }

  const getStatus = (doc)=>{
    return doc.data().cleaned ? "Cleaned" : "Not cleaned yet"
  }

  const handleReprocess = async (doc) => {
    console.log(`reprocess clicked: `,doc.id, doc.data());
    const response = await reprocess({uniqueListingId: doc.id});
    console.log(response)
  };
  
  return (
    <>
    <div className="flex gap-2">
      {listingsValue &&<DefaultPagination page={page} handlePageChange={handlePageChange} listingsCount={listingsCount} listingsPerPage={listingsPerPage}></DefaultPagination>}
    </div>
    <div className="flex gap-2">
      Cleaned: {cleanedCount}
    </div>
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <div className="flow-root">
            <Typography variant="h6" color="white" className="float-left">
              Re-processing Information
            </Typography>
        
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {/* <div className="w-full min-w-[640px]">
            <Typography variant="h6" color="pink">
              You are looking at page: {page}, total count {listingsCount}
            </Typography>
          </div> */}

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "Unique ID",
                  "Status",
                  "Price",
                  "Max. Price",
                  "PCT. Change",
                  ""
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listingsValue &&
                listingsValue.docs.map((doc, index) => {
                  const className = `py-3 px-5 ${
                    index === listingsValue.docs.length - 1
                      ? ""
                      : "border-b border-blue-gray-50"
                  }`;

                  return (
                    <tr key={`row${index}`}>
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {documentId(doc)}
                        </Typography>
                      </td>

                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {getStatus(doc)}
                        </Typography>
                      </td>

                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatPrice(doc.data().info.price)}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().info.maxPrice}
                        </Typography>
                      </td>

                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatPercentage(doc.data().info.price_change_pct_abs)}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Button key={`reprocess_${doc.id}`} className="border p-1 bg-blue-300 text-white" onClick={()=>handleReprocess(doc)}>Re-process</Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
    
    </>
  );
}

export default ReprocessingView;
