import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  getAuth,
} from "firebase/auth";

export const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logOut = async () => {
    await auth.signOut();
    navigate("/");
    console.log("You are logged out");
  };

  const logIn = async (email, password) => {
    console.log("logging in");
    await signInWithEmailAndPassword(auth, email, password);
    navigate("/dashboard/listings");
  };

  const checkLoginAndRedirect = ()=>{
    if (auth.currentUser){
      navigate("/dashboard/listings");
    }
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("current user:", currentuser);
      setUser(currentuser);
      if(auth.currentUser){
        auth.currentUser.getIdTokenResult()
          .then((idTokenResult)=>{
            if (idTokenResult.claims.admin){
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
          })
      } else {
        setIsAdmin(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  return (
    <UserContext.Provider value={{ createUser, user, logOut, logIn, isAdmin, checkLoginAndRedirect }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
