import { getFunctions, httpsCallable } from "firebase/functions";
import { connectFunctionsEmulator } from "firebase/functions";
import React, { useState } from "react";

import './Caller.css'

const Caller = (props) => {

    const functions = getFunctions();
    const [response, setResponse] = useState("");

    console.log('me ' + window.location.href);

    if (window.location.href.indexOf('localhost') > -1) {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001)
    }

    const func = httpsCallable(functions, props.functionName);

    async function callFunc() {
        console.log('func: ' + props.functionName, props.params);
        const response = await func(props.params);
        console.log(response)
        setResponse(response.data);
    }

    return (
        <div style={{border: '1px solid'}}>
            <div>
                <button className="border px-6 py-2 my-4" onClick={callFunc}>{props.text}</button>
            </div>
            <div>
                Response: [{response}]
            </div>
        </div>

    );
};


export default Caller