import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dialog,
  Input,
} from "@material-tailwind/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DashboardConstraintSelector from "../../pages/dashboard/DashboardConstraintSelector";

export function DashboardEditDialog({open, handleOpen, handleSave, handleQuit, data, mode}) {

  // console.log("dialog data", data)

  const defaultName = (mode === "add" ? "" : data.name) 
  const [name, setName] = useState(defaultName);
  
  const defaultManufacturer = mode === "add" ? {label: "All Manufacturers", value: "All Manufacturers"} : data.manufacturers[0];
  const defaultModel = mode === "add" ?  [{label: "All Models", value: "All Models"}] : data.models;
  
  const [selectedManufacturer, setSelectedManufacturer] = useState(defaultManufacturer);
  const [selectedModel, setSelectedModel] = useState(defaultModel);

  const updateName = (e) =>{
    setName(e.target.value);
  }

  const handleCloseInternal = () => {
    if (mode === "add") clear();
    handleQuit();
  }

  const handleSaveInternal = (name, selectedManufacturer, selectedModel) => {
    handleSave(name, selectedManufacturer, selectedModel)
    if (mode === "add") clear();
  }

  const clear = () => {
    setName('');
    setSelectedManufacturer({label: "All Manufacturers", value: "All Manufacturers"});
    setSelectedModel({label: "All Models", value: "All Models"});
  }

  return (
      
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        outsidePress={()=>handleCloseInternal()}
        escapeKey={()=>handleCloseInternal()}
        className="bg-transparent shadow-none backdrop:none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
           
            <Input label="Name" size="lg" value={name} onChange={(e)=>updateName(e)}/>

            <DashboardConstraintSelector 
              selectedManufacturer={selectedManufacturer}
              selectedModel={selectedModel}
              setSelectedManufacturer={setSelectedManufacturer}
              setSelectedModel={setSelectedModel}
            ></DashboardConstraintSelector>

          </CardBody>
          <CardFooter className="pt-0">
            <Button className="border p-1 bg-blue-300 text-white" variant="gradient" onClick={(e)=> handleSaveInternal(name, selectedManufacturer, selectedModel)} fullWidth>
              Save
            </Button>
            <Button
            variant="text"
            color="red"
            onClick={()=>handleCloseInternal()}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          </CardFooter>
        </Card>
      </Dialog>


  );
}

DashboardEditDialog.defaultProps = {
  color: "blue",
  footer: null,
};

DashboardEditDialog.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  icon: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
};

DashboardEditDialog.displayName = "/src/widgets/cards/dashboard-edit-dialog.jsx";

export default DashboardEditDialog;
