import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  Typography,
} from "@material-tailwind/react";
import { checkIfImageExists, formatSource, friendlyTimeFormat } from "../utils";
import { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import db from "../firebase";

export function ListingDetailImage({
  src,
  title,
  site,
  reg,
  listingURL,
  lastSeen,
  keyword,
  location,
  country,
  year
}) {
  const [imageURL, setImageURL] = useState("/small-airplane.png");
  const [isActive, setIsActive] = useState("loading...");

  useEffect(() => {
    let imageURL = src;
    if (src) {
      if (site === "planecheck") {
        imageURL = src.replace("thumb_", "");
      }
    }

    checkIfImageExists(imageURL, (exists) => {
      if (exists) {
        setImageURL(imageURL);
      }
    });
  }, [src, site]);

  useEffect(() => {
    // compares the listing scrapeTs with the last time we scraped the source/keyword combination
    // if the last scrape for the source/keyword was greater than listing's scrape TS it means
    // that this listing is no longer active.

    const scrapesCollection = "scrapes_v1";
    // console.log(i, listing.keyword, listing.source, listing.scrapeTs);
    const path = `${site}_listings/${keyword}/${scrapesCollection}`;
    console.log(path);
    const queryScrapes = query(
      collection(db, path),
      orderBy("started", "desc"),
      limit(2)
    );
    getDocs(queryScrapes).then((allScrapes) => {

      const scrape = allScrapes.docs[0];
      if (scrape){
        if (!lastSeen || !scrape.data()) {
          console.log("*** could not find dates", scrape.data().started);
          return;
        }

        const isActive = lastSeen >= scrape.data().started;
        setIsActive(isActive);
      }
        
      
    });
  });

  return (
    <Card>
      <CardHeader color="blue-gray" className="relative">
        <img
          className="h-full w-full rounded-xl object-cover"
          src={imageURL}
          alt=""
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-3">
          {title ? title : "-"}
        </Typography>
        <Typography variant="h6">Registration: {reg ? reg : "-"}</Typography>
        <Typography>Year: {year}</Typography>
        <Typography>Source: {site ? formatSource(site) : "-"}</Typography>
        <Typography>Location: {location ? location : "-"}</Typography>
        <Typography>Country: {country ? country : "-"}</Typography>
        {/* <Typography>Active: {isActive.toString()}</Typography> */}

        <Typography>
          Last Seen: {lastSeen ? friendlyTimeFormat(lastSeen) : "-"}
        </Typography>                
        <a
          target="_blank"
          href={listingURL}
          rel="noreferrer"
          className="underline"
        >
          <Typography variant="small">Original Listing</Typography>
        </a>
        <div className="grid py-3">
        <Chip
            variant="gradient"
            color={
              isActive
                ? "green"
                : "blue-gray"
            }
            value={
              isActive
                ? "active"
                : "inactive"
            }
            className="py-1 px-2 text-[10px] font-medium text-center justify-self-start"
          />
        </div>
        
      </CardBody>
    </Card>
  );
}
