import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { Link, useSearchParams } from "react-router-dom";
import { formatKeyword, formatPrice, friendlyTimeFormat, friendyManufacturerName, NO_PRICE_STR } from "../../utils";
import {
  connectFunctionsEmulator,
  getFunctions,
  // httpsCallable,
} from "firebase/functions";
// import { ContextMenu } from "../../components/ContextMenu";
import { SortMenu } from "./SortMenu";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import {
  collection,
  endBefore,
  getCountFromServer,
  limit,
  limitToLast,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../../firebase";
import { DefaultPagination } from "../../components/Pagination";
import ConstraintSelector from "./ConstraintSelector";

export function ListingAggregationsV1() {
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };
  const dataContext = useContext(DataContext);
  const [sortField, setSortField] = useState("last_change_dt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [manufacturers, setManufacturers] = useState({});
  const [sources, setSources] = useState({});

  
  const [hideZeroPriceChange, setHideZeroPriceChange] = useState(true);
  const [page, setPage] = useState(1);
  // const [checkedImages, setCheckedImages] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  
  if (searchParams.has("sf")){
    if (sortField !== searchParams.get('sf')){
      setSortField(searchParams.get('sf'))
    }
  }

  if (searchParams.has("sd")){
    if (sortDirection !== searchParams.get('sd')){
      setSortDirection(searchParams.get('sd'))
    }
  }

  if (searchParams.has("z")){
    if (hideZeroPriceChange.toString() !== searchParams.get('z')){

      setHideZeroPriceChange(searchParams.get('z') === "true"? true: false)
    }
  }

  if (searchParams.has("scs")){
    if (JSON.stringify(sources) !== searchParams.get('scs')){
      setSources(JSON.parse(searchParams.get('scs')));
    }
  }

  if (searchParams.has("m")){
    if (JSON.stringify(manufacturers) !== searchParams.get('m')){
      setManufacturers(JSON.parse(searchParams.get('m')));
    }
  }

  const sortConstraints = [];

  if (hideZeroPriceChange) {
    if (sortField === "no_price_changes") {
      sortConstraints.push(where("no_price_changes", ">", 0));
    // } else if (sortField === "days_since_price_change") {
      // days_since_price_change is -1 if there has not been a price change
      // means that ordering by days_since_price_change will not display
      // listings where the price has not change
      // these will be retured when ordering by no_price_chagnes
      // sortConstraints.push(where("days_since_price_change", "!=", -1));
    } else if (sortField === "last_change_dt") {
      sortConstraints.push(where("last_change_dt", "!=", -1));
    } else if (sortField === "price_change_usd") {
      sortConstraints.push(where("price_change_usd", "!=", NaN));
    } else if (sortField === "price_usd") {
      sortConstraints.push(where("price_usd", "!=", NaN));
    } else if (sortField === "price_change_pct_usd") {
      sortConstraints.push(or((where("price_change_pct_usd", "<", 0 )),(where("price_change_pct_usd", ">", 0 ))));
      sortConstraints.push(where("price_change_pct_usd", "!=", NaN));
    } else if (sortField === "price_range_usd") {
      sortConstraints.push(or((where("price_range_usd", "<", 0 )),(where("price_range_usd", ">", 0 ))));
      sortConstraints.push(where("price_range_usd", "!=", NaN));
    } else if (sortField === "price_change_pct_abs_usd") {
      sortConstraints.push(where("price_change_pct_abs_usd", ">", 0));
    }
  }

  // const manufacturerConstraints = [];
  // const manufacturersKeys = Object.keys(manufacturers);
  // for (let i = 0; i < manufacturersKeys.length; i++) {
  //   const key = manufacturersKeys[i];
  //   const filter = key.split(",");
  //   if (manufacturers[key]) {
  //     console.log('adding filter to include: ', key);
  //     console.log(filter)
  //     manufacturerConstraints.push(where("manufacturer", "in", filter));
  //   }
  // }

  // const sourceConstraints = [];
  // const sourcesKeys = Object.keys(sources);
  // for (let i = 0; i < sourcesKeys.length; i++) {
  //   const key = sourcesKeys[i];
  //   if (sources[key]) {
  //     console.log('adding SOURCE filter to include' + key);
  //     sourceConstraints.push(where("source", "==", key));
  //   }
  // }

  let sourceConstraints = [];
  Object.keys(sources).forEach((s)=>{
    if (sources[s].selected){
      sourceConstraints = sourceConstraints.concat(s);
    }
  })

  const constraints = [];
  constraints.push(orderBy(sortField, sortDirection));

  const selectedModel = dataContext.getConstraintState().selectedModel;
  const selectedManufacturer = dataContext.getConstraintState().selectedManufacturer;
  const selectedCategory = dataContext.getConstraintState().selectedCategory;

  const wheres = [];
  if (selectedModel.value && selectedModel.value.ids && selectedModel.value.ids ){
    wheres.push(where("model", "in", selectedModel.value.ids))
  }
  if (selectedManufacturer.value && selectedManufacturer.value.ids && selectedManufacturer.value.ids.length > 0){
    wheres.push(where("manufacturer", "in", selectedManufacturer.value.ids))
  }
  if (selectedCategory.value && selectedCategory.value.ids && selectedCategory.value.ids.length > 0){
    wheres.push(where("category", "in", selectedCategory.value.ids))
  }
  
  
  const queryAggregationsDataNoLimit = query(
    collection(db, "listing_aggregations_v1"),
    ...constraints, ...wheres
  );
  
  const listingsPerPage = 25;
  
  const docCursors = useRef()
  
  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      constraints.push(startAfter(docCursors.current.docToStartAfter))
      constraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      constraints.push(endBefore(docCursors.current.docToEndBefore))
      constraints.push(limitToLast(listingsPerPage));
    }
  } else {
    constraints.push(limit(listingsPerPage));
  }
  
  const queryAggregationsData = query(
    collection(db, "listing_aggregations_v1"),
    ...constraints, ...wheres
  );


  const [listingsValue] = useCollection(queryAggregationsData, options);
  const [listingsCount, setListingCount] = useState();
  // const [listingStatus, setListingStatus] = useState({});

  useEffect(()=>{
    getCountFromServer(queryAggregationsDataNoLimit)
    .then((snapshot)=>{
      console.log(`got count for query without limit:${snapshot.data().count}`)
      setListingCount(snapshot.data().count);
    })
  }, [queryAggregationsDataNoLimit])

  const handlePageChange = useCallback((nextPage)=>{
    if (listingsValue){
      setPage((page)=>{
        // console.log(`current page is ${page}, next page is ${nextPage}`)

        if (nextPage < page){
          // console.log('setting doc to end before ', listingsValue.docs[0])
          docCursors.current = { direction: "back", docToEndBefore: listingsValue.docs[0]};
        } else {
          // console.log('setting doc to start after ',  listingsValue.docs[listingsValue.docs.length-1])
          docCursors.current = { direction: "forward", docToStartAfter: listingsValue.docs[listingsValue.docs.length-1]};
        }
        // console.log('doc Cursors', docCursors)
        return nextPage;
      })
    }
  }, [listingsValue])

  // useEffect(() => {
  //   // compares the listing scrapeTs with the last time we scraped the source/keyword combination
  //   // of the last scrape for the source/keyword was greater than listing's scrape TS it means
  //   // that this listing is no longer active.
  //   if (listingsValue) {
  //     const promises = [];

  //     for (let i = 0; i < listingsValue.docs.length; i++) {
  //       const listing = listingsValue.docs[i].data();
  //       // console.log(i, listing.keyword, listing.source, listing.scrapeTs);
  //       const queryScrapes = query(
  //         collection(
  //           db,
  //           `${listing.source}_listings/${listing.keyword}/scrapes`
  //         ),
  //         orderBy("started", "desc"),
  //         limit(2)
  //       );
  //       promises.push(getDocs(queryScrapes));
  //     }
  //     Promise.all(promises).then((allScrapes) => {
  //       const newListingsStatus = {};

  //       for (let i = 0; i < allScrapes.length; i++) {
  //         const scrape = allScrapes[i].docs[0];
  //         // console.log(scrape.data())
  //         const listing = listingsValue.docs[i].data();
  //         if (!listing.scrapeTs || (scrape && !scrape.data().started)) {
  //           console.log("*** could not find dates", scrape.data().started);
  //           return;
  //         }

  //         const isActive = scrape && (listing.scrapeTs >= scrape.data().started);
  //         // listing.isActive = isActive;
  //         newListingsStatus[listing.uniqueListingId] = isActive;
  //         // console.log(i, listing.uniqueListingId, formatFirebaseTimestamp(listing.scrapeTs), formatFirebaseTimestamp(scrape.data().started), isActive)
  //       }
  //       // console.log('*** setting new listings status', newListingsStatus);
  //       // setListingStatus(newListingsStatus);
  //     });
  //   }
  // }, [listingsValue]);

  // useEffect(() => {
  //   if (listingsValue) {
  //     for (let i = 0; i < listingsValue.docs.length; i++) {
  //       const imageURL = listingsValue.docs[i].data().imageURL;
  //       checkIfImageExists(imageURL, (exists) => {
  //         const checkedURL = exists ? imageURL : "/small-airplane.png";
  //         setCheckedImages((cur) => {
  //           cur[listingsValue.docs[i].data().uniqueListingId] = checkedURL;
  //           return { ...cur };
  //         });
  //       });
  //     }
  //   }
  // }, [listingsValue]);

  const functions = getFunctions();
  if (window.location.href.indexOf("localhost") > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  // const handleReprocessAllAscrape = async (params) => {
  //   console.log("handleReprocessAllAscrape", params);
  //   const response = await httpsCallable(
  //     functions,
  //     "reprocessListingAggregation"
  //   )(JSON.parse(JSON.stringify(params)));
  //   console.log(response);
  // };

  // const menuItems = [
  //   { name: "Reprocess AllScrape", func: handleReprocessAllAscrape },
  // ];

  return (
    <>
    
    {/* <SourceSelector setSources={setSources} sources={sources}/> */}
    <div className="flex gap-2">
          <ConstraintSelector/>
          
      {listingsValue &&<DefaultPagination page={page} handlePageChange={handlePageChange} listingsCount={listingsCount} listingsPerPage={listingsPerPage}></DefaultPagination>}
    
        </div>
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <div className="flow-root">
            <Typography variant="h6" color="white" className="float-left">
              Price Analysis
            </Typography>
            <SortMenu
              sortField={sortField}
              sortDirection={sortDirection}
              setSortField={setSortField}
              setSortDirection={setSortDirection}
              hideZeroPriceChange={hideZeroPriceChange}
              setHideZeroPriceChange={setHideZeroPriceChange}
              handlePageChange={handlePageChange}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              gridCols={10}
              relWidth={50}
            ></SortMenu>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {/* <div className="w-full min-w-[640px]">
            <Typography variant="h6" color="pink">
              You are looking at page: {page}, total count {listingsCount}
            </Typography>
          </div> */}

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "Title",
                  // "Active",
                  "price",
                  "Δ",
                  "Δ%",
                  // "price change (abs)",
                  "active",
                  "last Δ",
                  "#Δ",
                  // "min price",
                  // "max price",
                  "Manufacturer",
                  "Source",
                  // "First",
                  "last Seen",
                  // "",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listingsValue &&
                listingsValue.docs.map((doc, index) => {
                  const className = `py-3 px-5 ${
                    index === listingsValue.docs.length - 1
                      ? ""
                      : "border-b border-blue-gray-50"
                  }`;

                  return (
                    <tr key={`row${index}`}>
                      <td className={className}>
                        <div className="flex items-center gap-4">
                          {/* <Avatar
                              src={checkedImages[doc.data().uniqueListingId]}
                              alt={index}
                              size="sm"
                            /> */}
                          <Link
                            to={`/dashboard/listing-details/${
                              doc.data().uniqueListingId
                            }`}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {doc.data().title}
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=""
                            >
                              {doc.data().reg ? doc.data().reg: ""} 
                            </Typography>
                          </Link>
                        </div>
                      </td>
                      {/* <td className={className}>
                        {listingStatus[doc.data().uniqueListingId] &&
                          <Chip
                            variant="gradient"
                            color={
                              listingStatus[doc.data().uniqueListingId]
                                ? "green"
                                : "blue-gray"
                            }
                            value={
                              listingStatus[doc.data().uniqueListingId]
                                ? "active"
                                : "inactive"
                            }
                            className="py-0.5 px-2 text-[10px] font-medium text-center	"
                          />
                        }
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatPrice(doc.data().price, doc.data().currency)}
                        </Typography>
                      </td>

                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().currency}
                        </Typography>
                      </td> */}
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatPrice(doc.data().price_range,doc.data().currency )}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          color={doc.data().price_change_usd >= 1 ? "green" : "red"}
                          className="text-xs font-medium "
                        >
                          {formatPrice(
                            doc.data().price_change_usd,
                            "USD"
                          )}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          color={
                            doc.data().price_change_pct * 100 >= 0
                              ? "green"
                              : "red"
                          }
                          className="text-xs font-medium "
                        >
                          {isNaN(doc.data().price_change_pct) ? NO_PRICE_STR : Math.floor(doc.data().price_change_pct * 100) + "%"}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().days_active
                            ? Math.ceil(doc.data().days_active)
                            : ""}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().days_active
                            ? Math.ceil(doc.data().days_active)
                            : ""}
                        </Typography>
                      </td>                      
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {friendlyTimeFormat(doc.data().last_change_dt)}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {daysSinceLastPriceChange(doc)}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {Math.ceil(doc.data().no_price_changes).toString()}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatPrice(doc.data().min_price,doc.data().currency )}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatPrice(doc.data().max_price,doc.data().currency )}
                        </Typography>
                      </td> */}
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatSource(doc.data().source)}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {friendyManufacturerName(dataContext, doc.data().manufacturer)}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatKeyword(doc.data().source)}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatFirebaseTimestamp(doc.data().first_seen)}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {friendlyTimeFormat(doc.data().last_seen)}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatFirebaseTimestamp(doc.data().scrapeTs)}
                        </Typography>
                      </td> */}
                      {/* <td className={"visible"}>
                        <ContextMenu menuItems={menuItems} data={doc.data()} />
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
    
    </>
  );
}

export default ListingAggregationsV1;
